import React from 'react';

import { User, UserContextInterface } from 'types.d';

export const defaultUser: User = {
  isLoggedIn: false,
  username: null,
  accessToken: null,
  refreshToken: null,
};

const UserContext = React.createContext<Partial<UserContextInterface>>({ user: defaultUser });

export default UserContext;
