import axios, { AxiosInstance } from 'axios';

import { ApiResponse } from 'context/Items/ItemsContext.types';

/* eslint class-methods-use-this: off */
/* eslint no-param-reassign: off */
/* eslint no-underscore-dangle: off */
/* eslint prefer-promise-reject-errors: off */
class HttpClient {
  httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
    });

    this.httpClient.interceptors.request.use(
      async (config) => {
        const token = localStorage.getItem('accessToken');

        if (token) config.headers.Authorization = `Bearer ${token}`;

        return Promise.resolve(config);
      },
      (error) => Promise.reject(error),
    );

    this.httpClient.interceptors.response.use(
      async (response) => Promise.resolve(response.data),
      async (error) => {
        // return;
        const originalRequest = error.config;

        if (!error.response || originalRequest._retry) {
          return Promise.reject(error);
        }
        const { status } = error.response;

        if (status !== 401) return Promise.reject(error);

        originalRequest._retry = true;

        return axios
          .post(`${process.env.REACT_APP_SECURITY_API_URL}/users/refresh-token`, {
            accessToken: localStorage.getItem('accessToken'),
            refreshToken: localStorage.getItem('refreshToken'),
          })
          .then((result) => {
            const { accessToken, refreshToken } = result.data;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            originalRequest.headers.Authorization = `Bearer ${accessToken}`;

            return axios(originalRequest);
          });
      },
    );
  }

  // AUTH
  loginWithKeycloak(code: string) {
    return axios
      .get(
        `${process.env.REACT_APP_SECURITY_API_URL}/users/oauth-redirect/keycloak?code=${code}&redirectUrl=${process.env.REACT_APP_URL}/login-callback/keycloak`,
      )
      .then((res) => res.data);
  }

  isAuthenticated() {
    const token = localStorage.getItem('accessToken');

    if (!token) return Promise.reject(false);

    return axios.get(`${process.env.REACT_APP_SECURITY_API_URL}/users/is-authenticated`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
  }

  isAuthenticatedWithRetry() {
    return this.isAuthenticated()
      .then((result) => {
        if (result.data) return result.data;

        return this.refreshToken();
      })
      .then(this.isAuthenticated);
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');

    if (!refreshToken) return Promise.resolve();

    return axios
      .post(`${process.env.REACT_APP_SECURITY_API_URL}/users/refresh-token`, {
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
      })
      .then((result) => {
        const { accessToken, refreshToken: rt } = result.data;
        localStorage.setItem('accessToken', accessToken);
        localStorage.setItem('refreshToken', rt);
      });
  }

  getItems() {
    const token = localStorage.getItem('accessToken');
    let headers;

    if (token) {
      headers = {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      };
    }

    return axios.get<ApiResponse>(process.env.REACT_APP_API_URL, {
      headers,
    });
  }
}

export default new HttpClient();
