import { createContext } from 'react';

import { ItemsContextState, ItemsContextDispatch } from './ItemsContext.types';
import { circleTitles } from 'utils/chart';

export const initialItemsContextState: ItemsContextState = {
  items: [],
  filteredItems: [],
  itemsWithCoordinates: [],
  search: '',
  teams: [],
  filterTeam: '',
};

export const itemPointsRadius = 4;
export const titlePoints = circleTitles();

export const ItemsStateContext = createContext<ItemsContextState>(initialItemsContextState);
// eslint-disable-next-line @typescript-eslint/no-empty-function
export const ItemsDispatchContext = createContext<ItemsContextDispatch>(() => {});
