import React, { memo, useContext } from 'react';

import { LogoTSH } from 'ui/svg/logo/LogoTSH';
import { LogIn } from 'ui/svg/logIn/LogIn';
import { LogOut } from 'ui/svg/logOut/LogOut';
import styles from './Sidebar.module.css';
import { Accordion } from 'ui/accordion/Accordion';
import { TechnologyTypes } from 'utils/constants';
import { Search } from 'ui/search/Search';
import { SidebarProps } from './Sidebar.types';
import { groupDataByFields } from 'utils/sidebar';
import { Teams } from 'ui/teams/Teams';
import UserContext from 'context/User/UserContext';

export const Sidebar = memo(function Sidebar({ items }: SidebarProps) {
  const groupedData = groupDataByFields(items, TechnologyTypes, 'type');
  const { user, logOut } = useContext(UserContext);

  return (
    <div className={styles.sidebar} data-testid="sidebar">
      <div className={styles.flexRow}>
        <a href="/">
          <LogoTSH />
        </a>
        {user && !user.isLoggedIn ? (
          <a title="Log in" href={process.env.REACT_APP_KEYCLOAK_AUTH_URL} className={styles.loggingButton}>
            <LogIn />
            <span>Log in</span>
          </a>
        ) : (
          <button type="button" title="Log out" onClick={logOut} className={styles.loggingButton}>
            <LogOut />
            <span>Log out</span>
          </button>
        )}
      </div>
      <h1 className={styles.title}>Tech Radar</h1>
      <h2 className={styles.subtitle}>Search for our best technologies</h2>
      <Search />
      <Teams />
      <Accordion data={groupedData} />
    </div>
  );
});
