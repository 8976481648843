import { ApiResponse } from 'context/Items/ItemsContext.types';

export const techResponse: Omit<ApiResponse, 'authentication'> = {
  categories: ['LIBRARIES_TOOLS', 'OTHERS', 'INFRASTRUCTURE_DEPLOYMENTS', 'PATTERNS_PROCESSES'],
  teams: ['NODE', 'QA', 'DEVOPS', 'PHP', 'FRONTEND', 'MOBILE', 'PM'],
  dictionary: {
    DEVOPS: 'DevOps',
    FRONTEND: 'Front-end',
    MOBILE: 'Mobile',
    NODE: 'Node',
    PHP: 'PHP',
    PM: 'Project Management',
    QA: 'Quality Assurance',
  },
  types: ['COMMON', 'SOMETIMES', 'EXPERIMENT', 'AVOID'],
  items: {
    LIBRARIES_TOOLS: [
      {
        name: 'Express.js',
        type: 'COMMON',
        teams: 'NODE,QA',
      },
      {
        name: 'TypeORM',
        type: 'COMMON',
        teams: 'NODE',
      },
      {
        name: 'PostgreSQL',
        type: 'COMMON',
        teams: 'NODE,DEVOPS,PHP',
      },
      {
        name: 'Awilix',
        type: 'COMMON',
        teams: 'NODE',
      },
      {
        name: 'Supertest',
        type: 'COMMON',
        teams: 'NODE,QA',
      },
      {
        name: 'Mocha',
        type: 'COMMON',
        teams: 'NODE,QA',
      },
      {
        name: 'Prettier',
        type: 'COMMON',
        teams: 'NODE,FRONTEND,QA',
      },
      {
        name: 'ESLint',
        type: 'COMMON',
        teams: 'NODE,FRONTEND,QA',
      },
      {
        name: 'Joi',
        type: 'COMMON',
        teams: 'NODE,QA',
      },
      {
        name: 'Babelsheet',
        type: 'COMMON',
        teams: 'NODE,PHP,FRONTEND',
      },
      {
        name: 'Swagger',
        type: 'COMMON',
        teams: 'NODE,PHP',
      },
      {
        name: 'PM2',
        type: 'SOMETIMES',
        teams: 'NODE',
      },
      {
        name: 'RxJS',
        type: 'SOMETIMES',
        teams: 'NODE,FRONTEND',
      },
      {
        name: 'Socket.io',
        type: 'SOMETIMES',
        teams: 'NODE',
      },
      {
        name: 'Plop',
        type: 'SOMETIMES',
        teams: 'NODE,FRONTEND',
      },
      {
        name: 'Serverless Framework',
        type: 'SOMETIMES',
        teams: 'NODE',
      },
      {
        name: 'Strapi',
        type: 'SOMETIMES',
        teams: 'NODE,FRONTEND',
      },
      {
        name: 'Deno',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'TypeDI',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'Lerna',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'Nest',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'GraphQLCodeGen',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'Bull',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'MikroORM',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'Cockatiel',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'Total.js',
        type: 'AVOID',
        teams: 'NODE',
      },
      {
        name: 'Bookshelf',
        type: 'AVOID',
        teams: 'NODE',
      },
      {
        name: 'Jira',
        type: 'COMMON',
        teams: 'PM,QA,NODE,PHP,MOBILE,FRONTEND',
      },
      {
        name: 'GSuite',
        type: 'COMMON',
        teams: 'PM',
      },
      {
        name: 'Confluence',
        type: 'COMMON',
        teams: 'PM,QA,NODE,PHP,MOBILE,FRONTEND',
      },
      {
        name: 'OfficeTimeline',
        type: 'COMMON',
        teams: 'PM',
      },
      {
        name: 'LucidChart',
        type: 'COMMON',
        teams: 'PM',
      },
      {
        name: 'Trello',
        type: 'SOMETIMES',
        teams: 'PM,QA,NODE,PHP,MOBILE,FRONTEND',
      },
      {
        name: 'Kubernetes',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Prometheus',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Grafana',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Helm',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Terraform',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Vault',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'sops',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'helmfile',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Jenkins',
        type: 'COMMON',
        teams: 'DEVOPS,PHP,NODE,QA',
      },
      {
        name: 'postman',
        type: 'COMMON',
        teams: 'DEVOPS,QA,NODE',
      },
      {
        name: 'Istio',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'ELK',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Keycloak',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Anchore Engine',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Nexus Sonatype',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Telegraf',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Jaeger',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Sonarqube',
        type: 'SOMETIMES',
        teams: 'DEVOPS,NODE',
      },
      {
        name: 'Ansible',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Operator Framework',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'kops',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'openldap',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'jmeter',
        type: 'SOMETIMES',
        teams: 'DEVOPS,QA',
      },
      {
        name: 'Loki',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Rook',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Harbor',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Argo',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Prow',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Tekton',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Kind',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'minikube',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'influxdb',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'kustomize',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'skaffold',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Kyma',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Bluehost',
        type: 'AVOID',
        teams: 'DEVOPS',
      },
      {
        name: 'VirtualBox',
        type: 'AVOID',
        teams: 'DEVOPS',
      },
      {
        name: 'puppet',
        type: 'AVOID',
        teams: 'DEVOPS',
      },
      {
        name: 'chef',
        type: 'AVOID',
        teams: 'DEVOPS',
      },
      {
        name: 'Symfony',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'PHPUnit',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'PHPSpec',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'Behat',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'PHP-CS-Fixer',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'Composer',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'Fly System',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'Doctrine',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'Laravel',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'Twig',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'Blade',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'PHP Stan',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'PHP MD',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'API Platform',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'Messenger',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'Tactitian',
        type: 'SOMETIMES',
        teams: 'PHP',
      },
      {
        name: 'Wordpress',
        type: 'EXPERIMENT',
        teams: 'PHP,FRONTEND',
      },
      {
        name: 'RoadRunner',
        type: 'EXPERIMENT',
        teams: 'PHP',
      },
      {
        name: 'codeception',
        type: 'AVOID',
        teams: 'PHP',
      },
      {
        name: 'React',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'React Hooks',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'React Context Api',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'React Fetching Library',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'React Testing Library',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'Cypress',
        type: 'COMMON',
        teams: 'FRONTEND,QA',
      },
      {
        name: 'React Final Form',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'i18next',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'Styled Components',
        type: 'COMMON',
        teams: 'FRONTEND',
      },
      {
        name: 'Angular',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'NGRX',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'Vuex',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'Redux',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'Vue.js',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'React',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'React Native',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'Expo',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'Storybook',
        type: 'SOMETIMES',
        teams: 'FRONTEND',
      },
      {
        name: 'Svelte',
        type: 'EXPERIMENT',
        teams: 'FRONTEND',
      },
      {
        name: 'AngularJS',
        type: 'AVOID',
        teams: 'FRONTEND',
      },
      {
        name: 'Kakunin',
        type: 'COMMON',
        teams: 'QA',
      },
      {
        name: 'Protractor',
        type: 'COMMON',
        teams: 'QA',
      },
      {
        name: 'Jest',
        type: 'COMMON',
        teams: 'QA,FRONTEND',
      },
      {
        name: 'QA Touch',
        type: 'COMMON',
        teams: 'QA',
      },
      {
        name: 'Testflight',
        type: 'COMMON',
        teams: 'QA',
      },
      {
        name: 'App tester',
        type: 'COMMON',
        teams: 'QA',
      },
      {
        name: 'Selenium Webdriver',
        type: 'COMMON',
        teams: 'QA',
      },
      {
        name: 'Puppeteer',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'Nightwatch',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'DBeaver',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'Sublist3r',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'Burp',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'OWASP ZAP',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'metasploit',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'Jasmine',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'Mailcatcher',
        type: 'SOMETIMES',
        teams: 'QA',
      },
      {
        name: 'Mailhog',
        type: 'SOMETIMES',
        teams: 'QA,NODE,PHP',
      },
      {
        name: 'Tulpar',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'D-Tect',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'Hydra',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'theChoice',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'BatchExplorer',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'Microsoft Azure Storage Explorer',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'Behave',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'Tape',
        type: 'EXPERIMENT',
        teams: 'QA,NODE',
      },
      {
        name: 'CodeceptJS',
        type: 'AVOID',
        teams: 'QA',
      },
      {
        name: 'Retrofit',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'OkHttp',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'RxJava',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Kotlin',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Dagger2',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Mockito',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Google Maps',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Glide',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Timber',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Hawk',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Mapbox',
        type: 'SOMETIMES',
        teams: 'MOBILE',
      },
      {
        name: 'Room',
        type: 'SOMETIMES',
        teams: 'MOBILE',
      },
      {
        name: 'Navigation Components',
        type: 'SOMETIMES',
        teams: 'MOBILE',
      },
      {
        name: 'Mockk',
        type: 'SOMETIMES',
        teams: 'MOBILE',
      },
      {
        name: 'Fastlane',
        type: 'SOMETIMES',
        teams: 'MOBILE',
      },
      {
        name: 'Coroutines',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
      {
        name: 'Android Data Binding',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
      {
        name: 'Dart',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
      {
        name: 'BLoC',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
      {
        name: 'Volley',
        type: 'AVOID',
        teams: 'MOBILE',
      },
      {
        name: 'ObjectBox',
        type: 'AVOID',
        teams: 'MOBILE',
      },
      {
        name: 'Espresso',
        type: 'AVOID',
        teams: 'MOBILE',
      },
    ],
    OTHERS: [
      {
        name: 'TypeScript',
        type: 'COMMON',
        teams: 'NODE,FRONTEND,QA',
      },
      {
        name: 'GraphQL',
        type: 'SOMETIMES',
        teams: 'NODE,PHP',
      },
      {
        name: 'gRPC',
        type: 'SOMETIMES',
        teams: 'NODE,PHP',
      },
      {
        name: 'Server Side Events',
        type: 'EXPERIMENT',
        teams: 'NODE',
      },
      {
        name: 'Let’s Encrypt',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Golang',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'REST API',
        type: 'COMMON',
        teams: 'PHP,NODE',
      },
      {
        name: 'OAuth',
        type: 'COMMON',
        teams: 'PHP,NODE',
      },
      {
        name: 'JWT',
        type: 'COMMON',
        teams: 'PHP,NODE',
      },
      {
        name: 'Javascript',
        type: 'COMMON',
        teams: 'QA',
      },
      {
        name: 'Python',
        type: 'EXPERIMENT',
        teams: 'QA',
      },
      {
        name: 'Swift',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'Facebook Sign in',
        type: 'SOMETIMES',
        teams: 'MOBILE',
      },
      {
        name: 'Google Sign in',
        type: 'SOMETIMES',
        teams: 'MOBILE',
      },
      {
        name: 'Flutter',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
      {
        name: 'AR',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
      {
        name: 'Java',
        type: 'AVOID',
        teams: 'MOBILE',
      },
      {
        name: 'ObjC',
        type: 'AVOID',
        teams: 'MOBILE',
      },
    ],
    INFRASTRUCTURE_DEPLOYMENTS: [
      {
        name: 'Docker',
        type: 'COMMON',
        teams: 'NODE,DEVOPS,PHP,FRONTEND',
      },
      {
        name: 'Redis',
        type: 'COMMON',
        teams: 'NODE,DEVOPS,PHP,QA',
      },
      {
        name: 'Bitbucket Pipelines',
        type: 'COMMON',
        teams: 'NODE,DEVOPS,PHP,FRONTEND,QA,MOBILE',
      },
      {
        name: 'AWS S3',
        type: 'COMMON',
        teams: 'NODE,DEVOPS,PHP',
      },
      {
        name: 'AWS Lambda',
        type: 'SOMETIMES',
        teams: 'NODE,DEVOPS',
      },
      {
        name: 'MongoDB',
        type: 'SOMETIMES',
        teams: 'NODE,PHP',
      },
      {
        name: 'ElasticSearch',
        type: 'SOMETIMES',
        teams: 'NODE,DEVOPS,PHP',
      },
      {
        name: 'Firebase',
        type: 'SOMETIMES',
        teams: 'NODE,MOBILE,FRONTEND,PHP',
      },
      {
        name: 'RabbitMQ',
        type: 'SOMETIMES',
        teams: 'NODE,PHP',
      },
      {
        name: 'AWS SQS',
        type: 'EXPERIMENT',
        teams: 'NODE,DEVOPS',
      },
      {
        name: 'AWS SNS',
        type: 'EXPERIMENT',
        teams: 'NODE,DEVOPS',
      },
      {
        name: 'Vagrant',
        type: 'AVOID',
        teams: 'NODE,DEVOPS',
      },
      {
        name: 'AWS',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'Google Cloud Engine',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'nginx',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'cloudflare',
        type: 'COMMON',
        teams: 'DEVOPS',
      },
      {
        name: 'DigitalOcean',
        type: 'SOMETIMES',
        teams: 'DEVOPS',
      },
      {
        name: 'Nats',
        type: 'SOMETIMES',
        teams: 'DEVOPS,NODE',
      },
      {
        name: 'Microsoft Azure',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Knative',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Github Actions',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'kafka',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'MySQL',
        type: 'COMMON',
        teams: 'PHP,NODE',
      },
      {
        name: 'ECS',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'Travis CI',
        type: 'SOMETIMES',
        teams: 'PHP,NODE,QA',
      },
      {
        name: 'Gitlab CI',
        type: 'SOMETIMES',
        teams: 'PHP,NODE,FRONTEND,QA,DEVOPS,MOBILE',
      },
      {
        name: 'Neo4J',
        type: 'EXPERIMENT',
        teams: 'PHP',
      },
    ],
    PATTERNS_PROCESSES: [
      {
        name: 'CQRS',
        type: 'SOMETIMES',
        teams: 'NODE,PHP',
      },
      {
        name: 'DDD',
        type: 'SOMETIMES',
        teams: 'NODE,PHP',
      },
      {
        name: 'Agile (Scrum-based)',
        type: 'COMMON',
        teams: 'PM',
      },
      {
        name: 'Kanban',
        type: 'SOMETIMES',
        teams: 'PM,',
      },
      {
        name: 'Fixed Price / Waterfall',
        type: 'AVOID',
        teams: 'PM',
      },
      {
        name: 'Service Catalog',
        type: 'EXPERIMENT',
        teams: 'DEVOPS',
      },
      {
        name: 'Single instance deployments',
        type: 'AVOID',
        teams: 'DEVOPS',
      },
      {
        name: 'TDD',
        type: 'COMMON',
        teams: 'PHP,NODE',
      },
      {
        name: 'BDD',
        type: 'COMMON',
        teams: 'PHP',
      },
      {
        name: 'Event Sourcing',
        type: 'EXPERIMENT',
        teams: 'PHP',
      },
      {
        name: 'Event Storming',
        type: 'EXPERIMENT',
        teams: 'PHP',
      },
      {
        name: 'Clean architecture',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'MVP',
        type: 'COMMON',
        teams: 'MOBILE',
      },
      {
        name: 'MVVM',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
      {
        name: 'MVVM-C',
        type: 'EXPERIMENT',
        teams: 'MOBILE',
      },
    ],
  },
};
