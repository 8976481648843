/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { MouseEvent, useEffect, useState } from 'react';
import clsx from 'clsx';

import styles from './Chart.module.css';
import { CategoryCircle, ChartProps } from './Chart.types';
import { d3 } from 'libs/d3';
import { Circle } from 'ui/svg/circle/Circle';
import { simplifyString } from 'utils/common';
import { useActiveItem } from 'hooks/useActiveItem';
import { useItemsState } from 'hooks/useItems';
import { ChartCircle } from './circle/ChartCircle';
import { Loader } from 'ui/loader/Loader';

export function Chart({ circles, titles, items }: ChartProps) {
  const [tip, setTip] = useState({ text: '', color: 'pink', active: false, x: 0, y: 0, circle: { x: 0, y: 0 } });
  const [init, setInit] = useState(true);
  const [activeCircle, setActiveCircle] = useState('');
  const activeItem = useActiveItem();
  const { filteredItems } = useItemsState();
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [count, setCount] = useState(0);

  const handleCircleItemEnter = (name: string) => (e: MouseEvent<SVGCircleElement>) => {
    const circle = document.querySelector(`.item-${name}`) as SVGCircleElement;

    setTip((state) => ({
      ...state,
      circle: {
        x: Number(circle.getAttribute('cx')),
        y: Number(circle.getAttribute('cy')),
      },
    }));

    setActiveCircle(e.currentTarget.dataset.circle || '');
  };

  const handleCircleItemMove = (itemName: string, color: string) => (e: MouseEvent<SVGCircleElement>) => {
    e.persist();
    setTip((state) => ({
      ...state,
      text: itemName,
      color,
      active: true,
      x: e.clientX + 5,
      y: e.pageY - 18,
    }));
  };

  const handleCircleItemLeave = () => {
    setTip((state) => ({ ...state, active: false }));
    setActiveCircle('');
  };

  useEffect(() => {
    if (!init) {
      d3.forceSimulation(items).force('collision', d3.forceCollide().radius(6));
    }
  }, [items, init]);

  useEffect(() => {
    if (activeItem.name) {
      const name = simplifyString(activeItem.name);
      const circle = document.querySelector(`.item-${name}`) as SVGCircleElement;

      setTip({
        text: activeItem.name,
        color: circle?.getAttribute('fill') ?? '',
        active: true,
        x: (circle && circle.getBoundingClientRect().left + 10) || 0,
        y: (circle && circle.getBoundingClientRect().top + window.scrollY - 14) || 0,
        circle: {
          x: Number(circle.getAttribute('cx')),
          y: Number(circle.getAttribute('cy')),
        },
      });
      setActiveCircle(`circle-${activeItem.type}`);
    } else {
      setTip((state) => ({ ...state, active: false }));
      setActiveCircle('');
    }
  }, [activeItem]);

  useEffect(() => {
    setVisibleItems(filteredItems.map(({ name }) => simplifyString(name)));
  }, [filteredItems]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (items.length && count <= items.length) {
      const counter = setTimeout(() => {
        setCount((t) => t + 1);
      }, 5);

      return () => clearTimeout(counter);
    }
  }, [count, items.length]);

  useEffect(() => {
    setInit(false);
  }, []);

  return (
    <div className={styles.chart}>
      <Loader visible={!items.length} />
      <div
        className={clsx(styles.tooltipContainer, tip.active && styles.tooltipActive)}
        style={{ left: tip.x, top: tip.y }}
      >
        <div className={styles.tooltipArrow} style={{ borderRightColor: tip.color }} />
        <div className={styles.tooltip} style={{ backgroundColor: tip.color }}>
          {tip.text}
        </div>
      </div>

      <svg id="radar" className={clsx(styles.radar, !items.length && styles.radarHidden)} viewBox="0 0 730 730">
        <g transform="translate(365,365)">
          <g>
            {circles.map(({ r, classNameCircle, name }: CategoryCircle) => (
              <ChartCircle
                activeCircle={activeCircle}
                classNameCircle={classNameCircle}
                name={name}
                r={r}
                setActiveCircle={setActiveCircle}
                key={`${classNameCircle}-container`}
                init={init}
              />
            ))}
          </g>
          <g id="circles">
            <Circle
              count={count}
              cx={tip.circle.x}
              cy={tip.circle.y}
              r={12}
              fill={tip.color}
              className={clsx('circle-indicator', tip.active && 'active')}
            />
            {items.map(({ name, r, x, y, color, type }, index) => {
              const simplifiedName = simplifyString(name);
              const circleType = `circle-${type.toLowerCase()}`;
              const classes = [
                'item',
                `item-${index}`,
                `item-${simplifiedName}`,
                activeItem.name && simplifyString(activeItem.name) !== simplifiedName && 'item-lighten',
                visibleItems && !visibleItems.includes(simplifiedName) && 'item-lighten',
              ];

              return (
                <React.Fragment key={`item-${index}`}>
                  {name ? (
                    <Circle
                      key={`item-${index}-${simplifiedName}`}
                      index={index}
                      count={count}
                      classes={classes}
                      cx={x}
                      cy={y}
                      r={r}
                      fill={color}
                      data-circle={circleType}
                      onMouseEnter={handleCircleItemEnter(simplifiedName)}
                      onMouseMove={handleCircleItemMove(name, color)}
                      onMouseLeave={handleCircleItemLeave}
                    />
                  ) : (
                    <Circle className="item-pe-none" cx={x} cy={y} r={3} />
                  )}
                </React.Fragment>
              );
            })}
            {titles.map(({ r, name }) => (
              <text
                key={`circle-${name.toLowerCase()}`}
                className={clsx(
                  'circle-title',
                  `circle-title-${name.toLowerCase()}`,
                  activeCircle === `circle-${name.toLowerCase()}` && 'active',
                  init && 'init',
                )}
                y={-r + 40}
              >
                {name}
              </text>
            ))}
          </g>
        </g>
      </svg>
    </div>
  );
}
