import clsx from 'clsx';
import React from 'react';

import { Circle } from '../../svg/circle/Circle';
import { TechnologyTypes } from '../../../utils/constants';

import { ChartCircleProps } from './ChartCircle.types';

const circleConfigs = {
  [TechnologyTypes.Common]: {
    width: '152.7%',
    height: '152.7%',
    x: '-26.3%',
    y: '-20.4%',
    offset1: '4',
    blur1: '4.5',
    offset2: '11',
    blur2: '14.5',
  },
  [TechnologyTypes.Sometimes]: {
    width: '112.7%',
    height: '112.7%',
    x: '-6.4%',
    y: '-4.4%',
    offset1: '4',
    blur1: '3.5',
    offset2: '7',
    blur2: '6.5',
  },
  [TechnologyTypes.Experiment]: {
    width: '105.8%',
    height: '105.8%',
    x: '-2.9%',
    y: '-2.1%',
    offset1: '3',
    blur1: '2.5',
    offset2: '4',
    blur2: '4.5',
  },
  [TechnologyTypes.Avoid]: {
    width: '102.7%',
    height: '102.7%',
    x: '-1.3%',
    y: '-1.2%',
    offset1: '1',
    blur1: '1.5',
    offset2: '1',
    blur2: '3',
  },
};

export const ChartCircle = ({ classNameCircle, activeCircle, r, setActiveCircle, name, init }: ChartCircleProps) => {
  const handleCircleMouseEnter = (circleName: string) => () => {
    setActiveCircle(circleName);
  };

  const handleCircleMouseLeave = () => {
    setActiveCircle('');
  };

  const config = circleConfigs[name];

  return (
    <g>
      <defs>
        <filter id={`${classNameCircle}-filter`} width={config.width} height={config.height} x={config.x} y={config.y}>
          <feOffset dy={config.offset1} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation={config.blur1} />
          <feColorMatrix
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
            values="0 0 0 0 0.0810117412 0 0 0 0 0.106789361 0 0 0 0 0.235762002 0 0 0 0.13 0"
          />
          <feOffset dy={config.offset2} in="SourceAlpha" result="shadowOffsetOuter2" />
          <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation={config.blur2} />
          <feColorMatrix
            in="shadowBlurOuter2"
            result="shadowMatrixOuter2"
            values="0 0 0 0 0.156862745 0 0 0 0 0.274509804 0 0 0 0 0.462745098 0 0 0 0.22 0"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="shadowMatrixOuter2" />
          </feMerge>
        </filter>
      </defs>
      <g className={clsx('circle-div-container', `${classNameCircle}-container`, init && 'init')}>
        <Circle
          id={`${classNameCircle}`}
          key={`${classNameCircle}-shadow`}
          r={r}
          filter={`url(#${classNameCircle}-filter)`}
        />
        <Circle
          id={`${classNameCircle}`}
          key={`${classNameCircle}-main`}
          r={r}
          className={clsx('circle-div', classNameCircle, activeCircle === classNameCircle && 'active')}
          onMouseEnter={handleCircleMouseEnter(classNameCircle)}
          onMouseLeave={handleCircleMouseLeave}
        />
      </g>
    </g>
  );
};
