import { Item } from 'ui/chart/Chart.types';

export const getRandomFromRange = (min: number, max: number) => Math.random() * (max - min) + min;

export const selectItemsByFieldValue = ({
  items = [],
  field,
  value,
}: {
  items: Item[];
  field: keyof Omit<Item, 'projects'>;
  value: string;
}) =>
  items.filter((item: Item) => {
    return item[field].toLowerCase() === value.toLowerCase();
  });

export const sortAlphabeticallyByField = (items: Item[], field: keyof Omit<Item, 'projects'>) =>
  [...items].sort((a, b) => (a[field] as string).localeCompare(b[field] as string));

export const simplifyString = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
