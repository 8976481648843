import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import httpClient from 'tools/httpClient';
import UserContext from 'context/User/UserContext';

export function Login() {
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code') || '';
  const history = useHistory();

  const [loginFailMessage, setLoginFailMessage] = useState('');

  const { logIn } = useContext(UserContext);

  useEffect(() => {
    async function loginWithKeycloak() {
      try {
        const { username, accessToken, refreshToken } = await httpClient.loginWithKeycloak(code);

        if (logIn) {
          logIn(username, accessToken, refreshToken);
        }
        history.push('/');
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setLoginFailMessage(`User with a given username does not exist.`);
        } else {
          setLoginFailMessage('Server error.');
        }
      }
    }
    loginWithKeycloak();
  }, [code, logIn, history]);

  return loginFailMessage ? (
    <div>
      Failed to login: {loginFailMessage} <a href="/">Go to login page to try again</a>
    </div>
  ) : (
    <div>Logging with Keycloak, please wait...</div>
  );
}
