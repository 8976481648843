import React, { useState, useEffect } from 'react';
import 'whatwg-fetch';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { User } from 'types.d';
import { Login } from 'pages/Login';
import { Home } from 'pages/Home';
import httpClient from 'tools/httpClient';
import UserContext from 'context/User/UserContext';
import styles from './App.module.css';
import { ActiveItemProvider } from 'hooks/useActiveItem';
import { ItemsContextController } from 'context/Items/ItemsContextController';

export function App() {
  const loggedOutUser: User = {
    isLoggedIn: false,
    username: null,
    accessToken: null,
    refreshToken: null,
  };

  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(loggedOutUser);

  const logIn = (username: string, accessToken: string, refreshToken: string) => {
    localStorage.setItem('username', username);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    setUser({ isLoggedIn: true, username, accessToken, refreshToken });
  };

  const logOut = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    setUser(loggedOutUser);
  };

  useEffect(() => {
    async function checkToken() {
      try {
        const valid = await httpClient.isAuthenticatedWithRetry();

        if (!valid) {
          logOut();
        } else if (!user.isLoggedIn) {
          const username = localStorage.getItem('username') || '';
          const accessToken = localStorage.getItem('accessToken') || '';
          const refreshToken = localStorage.getItem('refreshToken') || '';
          logIn(username, accessToken, refreshToken);
        }
      } catch (err) {
        logOut();
      }
      setLoading(false);
    }

    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isLoggedIn]);

  if (loading) {
    return null;
  }

  return (
    <div className={styles.app}>
      <UserContext.Provider value={{ user, logIn, logOut }}>
        <Router>
          <Switch>
            {!user.isLoggedIn && (
              <Route path="/login-callback/keycloak">
                <Login />
              </Route>
            )}
            <Route path="/">
              <ItemsContextController>
                <ActiveItemProvider>
                  <Home />
                </ActiveItemProvider>
              </ItemsContextController>
            </Route>
          </Switch>
        </Router>
      </UserContext.Provider>
    </div>
  );
}
