import React, { ChangeEvent, useState, useEffect } from 'react';
import { IoIosArrowUp } from 'react-icons/io';

import styles from './Teams.module.css';
import { useItemsDispatch, useItemsState } from 'hooks/useItems';

export function Teams() {
  const [filterTeam, setFilterTeam] = useState('');
  const itemsState = useItemsState();
  const itemsDispatch = useItemsDispatch();
  const handleTeamsChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setFilterTeam(event.target.value);
  };
  useEffect(() => {
    itemsDispatch({ ...itemsState, filterTeam });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterTeam]);

  return (
    <div>
      <h3 className={styles.title}>Teams</h3>
      <div className={styles.teams}>
        <select
          data-testid="teams-select"
          className={styles.select}
          placeholder="Teams technology"
          onChange={handleTeamsChange}
          value={filterTeam}
        >
          <option value="">All</option>
          {itemsState.teams.map((team) => (
            <option value={team.name} key={team.name}>
              {team.displayName}
            </option>
          ))}
        </select>
        <div className={styles.arrowContainer}>
          <IoIosArrowUp className={styles.arrow} />
        </div>
      </div>
    </div>
  );
}
