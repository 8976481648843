import React, { memo } from 'react';

import { TechnologyTypes, typesDescription } from '../../utils/constants';

import { AccordionSection } from './section/AccordionSection';
import { AccordionProps } from './Accordion.types';

export const Accordion = memo(function Accordion({ data }: AccordionProps) {
  return (
    <div className="accordion">
      {Object.entries(data).map(([type, items]) => (
        <AccordionSection
          key={type}
          type={type}
          items={items}
          description={typesDescription[type as TechnologyTypes]}
        />
      ))}
    </div>
  );
});
