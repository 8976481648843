export enum TechnologyTypes {
  Common = 'Common',
  Sometimes = 'Sometimes',
  Experiment = 'Experiment',
  Avoid = 'Avoid',
}

export const typesDescription = {
  [TechnologyTypes.Avoid]:
    'Whether it is due to poor performance or the emergence of better alternatives, we do not recommend using these technologies anymore.',
  [TechnologyTypes.Common]:
    'These technologies are our bread and butter. They are fundamental for many of our past and present projects and we constantly polish our skills in using them.',
  [TechnologyTypes.Experiment]:
    'We are always looking for technologies to improve our stack, but since we can’t lose our focus, we are very selective. These are currently in consideration.',
  [TechnologyTypes.Sometimes]:
    'Under specific circumstances, these technologies can be the best choice and we make sure that our developers know how and when to make use of them.',
};
