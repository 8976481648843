import React, { ChangeEvent } from 'react';
import { IoIosSearch } from 'react-icons/io';

import styles from './Search.module.css';
import { useItemsDispatch, useItemsState } from 'hooks/useItems';

export function Search() {
  const itemsState = useItemsState();
  const itemsDispatch = useItemsDispatch();
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    itemsDispatch({ ...itemsState, search });
  };

  return (
    <div className={styles.search}>
      <IoIosSearch className={styles.icon} size="1.5rem" />
      <input className={styles.input} type="search" placeholder="Search technology" onChange={handleSearch} />
    </div>
  );
}
