import styles from '../styles/Colors.module.css';

import { ItemWithCoordinates } from 'ui/chart/Chart.types';

export const rangeByType: { [key: string]: { r1: number; r2: number } } = {
  COMMON: { r1: 0, r2: 90 },
  SOMETIMES: { r1: 90, r2: 180 },
  EXPERIMENT: { r1: 180, r2: 270 },
  AVOID: { r1: 270, r2: 360 },
};

export const colorByCategory: { [key: string]: string } = {
  LIBRARIES_TOOLS: styles.colorCategoryBlue,
  OTHERS: styles.colorCategoryPink,
  INFRASTRUCTURE_DEPLOYMENTS: styles.colorCategoryYellow,
  PATTERNS_PROCESSES: styles.colorCategoryAquamarine,
};

type RangeConfiguration = {
  start: number;
  end: number;
  step: number;
  y: number;
  fy: number;
};

const circleTitlePointRadius = 3;

export const rangeCreateCircleTitlePoints = (...rangeConfigurations: RangeConfiguration[]): ItemWithCoordinates[] => {
  const arr: ItemWithCoordinates[] = [];
  const circleTitleCommonFields = {
    name: '',
    type: '',
    teams: '',
    color: '',
    r: circleTitlePointRadius,
  };

  rangeConfigurations.forEach(({ start, end, step, y, fy }) => {
    for (let i = start; i <= end; i += step) {
      arr.push({
        ...circleTitleCommonFields,
        x: i,
        y,
        fx: i,
        fy,
      });
    }
  });

  return arr;
};

export const circleTitles = (): ItemWithCoordinates[] => {
  const commonTitlesConfig = { start: -30, end: 30, step: 6 };
  const commonTitles = rangeCreateCircleTitlePoints(
    { ...commonTitlesConfig, y: -53, fy: -53 },
    { ...commonTitlesConfig, y: -59, fy: -59 },
    { start: -18, end: 18, step: 18, y: -47, fy: -47 },
  );

  const sometimesTitlesConfig = { start: -36, end: 36, step: 6 };
  const sometimesTitles = rangeCreateCircleTitlePoints(
    { ...sometimesTitlesConfig, y: -142, fy: -142 },
    { ...sometimesTitlesConfig, y: -148, fy: -148 },
  );

  const experimentTitlesConfig = { start: -36, end: 36, step: 6 };
  const experimentTitles = rangeCreateCircleTitlePoints(
    { ...experimentTitlesConfig, y: -232, fy: -232 },
    { ...experimentTitlesConfig, y: -238, fy: -238 },
  );

  const avoidTitlesConfig = { start: -18, end: 18, step: 6 };
  const avoidTitles = rangeCreateCircleTitlePoints(
    { ...avoidTitlesConfig, y: -322, fy: -322 },
    { ...avoidTitlesConfig, y: -328, fy: -328 },
  );

  return [...commonTitles, ...sometimesTitles, ...experimentTitles, ...avoidTitles];
};
