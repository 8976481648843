import React, { useState } from 'react';

import styles from './AccordionItem.module.css';
import { useSetActiveItem } from 'hooks/useActiveItem';
import { AccordionItemProps } from './AccordionItem.types';

export function AccordionItem({ name, type, displayName, projects = [] }: AccordionItemProps) {
  const setActiveItem = useSetActiveItem();
  const [expanded, setExpanded] = useState(false);

  return (
    <li
      className={styles.item}
      onMouseEnter={() => {
        setActiveItem({ name, type });
        setExpanded(true);
      }}
      onMouseLeave={() => {
        setActiveItem({ name: '', type: '' });
        setExpanded(false);
      }}
    >
      {displayName}
      {projects.length > 0 && expanded && (
        <ul>
          {projects.map((project) => (
            <li key={project}>{project}</li>
          ))}
        </ul>
      )}
    </li>
  );
}
