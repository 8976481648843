import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { CircleProps } from './Circle.types';

export function Circle({ circleDefaultStyle, className, classes, index, count, ...props }: CircleProps) {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    if (index === count) {
      setHidden(false);
    }
  }, [index, count]);

  return (
    <circle
      {...circleDefaultStyle}
      {...props}
      className={className || (classes && clsx(...classes, hidden && 'hidden'))}
      data-testid="circle"
    />
  );
}
