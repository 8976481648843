import React from 'react';
import clsx from 'clsx';

import './Loader.css';
import { LoaderProps } from './Loader.types';

export function Loader({ visible }: LoaderProps) {
  return (
    <div className={clsx('loader-radar', !visible && 'hidden')}>
      <div />
      <div />
      <div />
    </div>
  );
}
