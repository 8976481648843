import { useContext } from 'react';

import { ItemsStateContext, ItemsDispatchContext } from 'context/Items/ItemsContext';

export const useItemsState = () => {
  const context = useContext(ItemsStateContext);

  if (context === undefined) {
    throw new Error('useItemsState must be used within a ItemContextController');
  }

  return context;
};
export const useItemsDispatch = () => {
  const context = useContext(ItemsDispatchContext);

  if (context === undefined) {
    throw new Error('useItemsDispatch must be used within a ItemContextController');
  }

  return context;
};

export const useItems = [useItemsState, useItemsDispatch];
