import React, { createContext, useState, Dispatch, SetStateAction } from 'react';

import { ActiveItemContextState, ActiveItemProviderProps } from './useActiveItem.types';

const ActiveItemContext = createContext<ActiveItemContextState | undefined>(undefined);
// eslint-disable-next-line @typescript-eslint/no-empty-function
const SetActiveItemContext = createContext<Dispatch<SetStateAction<ActiveItemContextState>>>(() => {});

const ActiveItemProvider = ({ children }: ActiveItemProviderProps) => {
  const [activeItem, setActiveItem] = useState({ name: '', type: '' });

  return (
    <ActiveItemContext.Provider value={activeItem}>
      <SetActiveItemContext.Provider value={setActiveItem}>{children}</SetActiveItemContext.Provider>
    </ActiveItemContext.Provider>
  );
};

const useActiveItem = () => {
  const context = React.useContext(ActiveItemContext);

  if (context === undefined) {
    throw new Error('useActiveItem must be used within a ActiveItemProvider');
  }

  return context;
};

const useSetActiveItem = () => {
  const context = React.useContext(SetActiveItemContext);

  if (context === undefined) {
    throw new Error('useSetActiveItem must be used within a ActiveItemProvider');
  }

  return context;
};

export { ActiveItemProvider, useActiveItem, useSetActiveItem };
